import React from "react";
import { rehydrate } from "fela-dom";
import { ThemeProvider, StyleProvider, styleRenderer } from "ollui";
import theme from "./src/theme";
import "./global.css";

export const wrapRootElement = ({ element }) => {
  const renderer = styleRenderer();
  rehydrate(renderer);

  return (
    <StyleProvider renderer={renderer}>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </StyleProvider>
  );
};

// const addTawkTo = (url, callback) => {
//   var script = document.createElement("script");
//   script.async = true;
//   script.src = "https://embed.tawk.to/5ff9da80c31c9117cb6cffe6/1erjvr1jt";
//   script.charset = "UTF-8";
//   script.setAttribute("crossorigin", "*");

//   document.body.appendChild(script);
// };

// export const onClientEntry = () => {
//   window.onload = () => {
//     addTawkTo();
//   };
// };

export const onInitialClientRender = () => {
  // <script type="text/javascript">{`var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
  //           (function(){
  //           var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
  //           s1.async=true;
  //           s1.src='https://embed.tawk.to/5ff9da80c31c9117cb6cffe6/1erjvr1jt';
  //           s1.charset='UTF-8';
  //           s1.setAttribute('crossorigin','*');
  //           s0.parentNode.insertBefore(s1,s0);
  //           })();`}</script>;
  // const script = document.createElement("script");
  // script.async = true;
  // script.src = "https://embed.tawk.to/5ff9da80c31c9117cb6cffe6/1erjvr1jt";
  // script.charset = "UTF-8";
  // script.setAttribute("crossorigin", "*");

  // document.body.appendChild(script);
};
