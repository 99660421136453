import { DefaultTheme } from "ollui";

export default {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    contentBg: "#f9f9f9",
    bg: "#fff",
    border: "#eee",
    // secondaryBg: 'f5f5f5'
    navigationBg: "#f9f9f9",
    navigationBorder: "#f5f5f5",
    // navigationBg: "#2AA4FA",
    // navigationBorder: "#2A95FA",
    // primary: "#2EC2A4",
    // primary: "#386E7B",
    primary: "#222",
    secondary: "#444",
    primaryDark: "#444",
    // primaryDark: "#545d88",
    // primary: "#3c4469",
    // secondary: "#eb5d75",
    red: "#f17173",
    green: "#a3ceb8",
    yellow: "#F5F366",
    orange: "#F5A03A",
    bodySecondary: "#888",
    // green: '#B7E394',
    //   border: `#222`
  },
  font: "Roboto, sans-serif",

  //   #BF616A
  // #D08770
  // #EBCB8B
  // #A3BE8C
  // #B48EAD
  layout: {
    ...DefaultTheme.layout,
    radius: 4,
    // radius: 15,
    //   border: `#222`
  },
  radius: 4,
  grid: 8,
};

// export default {
//   ...DefaultTheme,
//   colors: {
//     ...DefaultTheme.colors,
//     contentBg: "#F8FBFF",
//     bg: "#fff",
//     border: "#f5f5f5",
//     // secondaryBg: 'f5f5f5'
//     navigationBg: "#fff",
//     navigationBorder: "#f5f5f5",
//     // navigationBg: "#2AA4FA",
//     // navigationBorder: "#2A95FA",
//     primary: "#6C8CB0",
//     // primary: "#2EC2A4",
//     // primary: "#222",
//     red: "#E78B84",
//     green: "#85BBB2",
//     yellow: "#F5F366",
//     orange: "#F5A03A",
//     // green: '#B7E394',
//     //   border: `#222`
//   },
//   layout: {
//     ...DefaultTheme.layout,
//     radius: 20,
//     //   border: `#222`
//   },
//   grid: 8,
// };
